.toggle_switch {
    position: relative;
    display: inline-block;
    width: 54px;
    height: 28px;
    margin-bottom: 0px !important;
}

.toggle_switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle_slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
}

.toggle_text {
    position: relative;
    display: inline-block;
    height: 28px;
    line-height: 28px;
    vertical-align: top;
    padding-left: 4px;
}

.toggle_slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .toggle_slider {
    background-color: #2196f3;
}

input:focus + .toggle_slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked + .toggle_slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.toggle_switch.toggle_small {
    width: 40px;
    height: 22px;
}

.toggle_slider.toggle_small {
    width: 40px;
    height: 22px;
    border-radius: 20px;
}

.toggle_text.toggle_small {
    height: 22px;
    line-height: 22px;
}

.toggle_slider.toggle_small:before {
    border-radius: 50%;
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 3px;
}

input:checked + .toggle_slider.toggle_small:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
}
