.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loadSpinner {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('./img/waiting.svg') 50% 50% no-repeat rgba(255, 255, 255, 0);
}

.loadSpinnerInline {
    text-align: center;
}

#logo {
    padding: 8px;
}

.tab-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: solid rgba(0, 0, 0, 0.125);
    border-width: 0px 1px 1px 1px;
    border-radius: 0px;
    min-height: 700px;
    padding: 1rem;
}

.no-margin {
    margin: 0px;
    padding: 0px;
}

.table-margin {
    margin: 0px;
    padding: 1rem;
}

.card-body {
    background-color: #9e9e9e1f;
    padding: 1rem;
}

.card {
    margin: 1rem;
}

.card-number {
    font-size: 3rem;
    text-align: center;
}

.nav-link {
    display: block;
    padding: 0.5rem 0.8rem;
}

.card-link {
    text-align: center;
}

.card-title {
    text-align: center;
}

.tab-table {
    margin-bottom: 1rem;
    margin-top: 0.25rem;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
    background-color: #d6eaf8;
}

.second-level-tab {
    margin: 0.8rem;
    background-color: #f8f9fa;
    border-radius: 0.25rem;
}

.card-like {
    padding-left: 1rem;
    padding-right: 1rem;
}

.card-like-row {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
}

.modal-back-drop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.25);
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 5%;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-dialog-box {
    width: 60%;
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.25rem;
    max-width: 800px;
    z-index: 1001;
}

.dialog-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    background-color: #2baceb;
}

.unit-dashboard-header {
    margin-top: 1.25rem;
}

.login-form {
    max-width: 600px;
    padding: 3rem;
    margin: 3rem;
    position: absolute;
    top: 30%;
    left: 47.5%;
    width: 80%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.25rem;
}

.install-sensor-form {
    max-width: 800px;
    padding: 3rem;
    margin: 3rem;
    position: absolute;
    top: 40%;
    left: 47.5%;
    width: 90%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 0.25rem;
}

.logo-img {
    padding-bottom: 1.5rem;
}

@media (max-width: 768px) {
    .nav-link {
        padding: 0.4rem 0.5rem;
    }

    .unit-dashboard-header {
        margin-left: -20px;
    }

    .tab-body {
        padding: 0.5rem 0rem 0rem 0rem;
        border-width: 0px;
    }

    .table-margin {
        margin: 0px;
        padding: 0.5rem;
    }

    .tab-table {
        margin-left: 0px;
        margin-right: 0px;
    }

    .card {
        margin: 0.5rem;
    }

    .second-level-tab {
        padding: 0.5rem;
    }

    .card-body {
        padding: 0.8rem;
    }

    .card-like {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .card-like-row {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }

    .modal-back-drop {
        padding: 0px;
    }

    .modal-dialog-box {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        box-shadow: initial;
    }

    .dialog-header {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: start;
        align-items: flex-start;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0.5rem 0.5rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }

    .install-sensor-form {
        position: initial;
        top: 0;
        left: 0;
        width: initial;
        max-width: initial;
        height: 100%;
        border-radius: 0px;
        box-shadow: initial;
        transform: initial;
        padding: 0rem;
        padding-top: 1rem;
        margin: 1rem;
    }
    .login-form {
        position: initial;
        top: 0;
        left: 0;
        width: initial;
        max-width: initial;
        height: 100%;
        border-radius: 0px;
        box-shadow: initial;
        transform: initial;
        padding: 0rem;
        padding-top: 1rem;
        margin: 1rem;
    }
}

.nav-item:hover {
    background-color: #d6eaf8;
    border-radius: 0.25rem;
}

.img-thumbnail-small {
    padding: 0.1rem;
    background-color: #fff;
    /* border: 1px solid #dee2e6; */
    /* border-radius: .25rem; */
    max-width: 100%;
    height: auto;
}

td {
    vertical-align: baseline;
}

.pic-padding {
    padding: 0px !important;
    margin: 0px !important;
    vertical-align: middle !important;
    text-align: center;
}

.btn-width-normal {
    width: 78pt !important;
}

.has-search .form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.clickable-row {
    cursor: pointer !important;
}

.alert-number {
    position: relative;
    top: -4pt;
    height: 12pt;
    width: 12pt;
    font-size: 7pt;
    display: none;
    text-align: center;
    vertical-align: text-middle;
    border-radius: 50%;
    color: #fff;
    background: #ff0000;
}

.alert-number-data {
    display: table-cell;
    vertical-align: middle;
    width: inherit;
    height: inherit;
    text-align: center;
}

.react-select-focus__control--is-focused {
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}

.react-select-focus__control--is-disabled {
    background-color: #e9ecef !important;
    opacity: 1 !important;
    border: 1px solid #ced4da !important;
}

.react-select-focus__single-value--is-disabled {
    color: #495057 !important;
}

.table-alert-detail td {
    padding: 0.1rem !important;
}

.setting-icon {
    display: block;
    border: 0;
    background: transparent;
    /*
  width: 32px;
  font-size: 28px;
  */
    float: right;

    padding-top: 4px;
    color: #18aaec;
    cursor: pointer;
}

.setting-icon:focus {
    color: #007bff;
    outline: none;
}

.hidden {
    display: none;
}

.pagination-pg-info {
    display: inline-block;
    border: 1px solid #dee2e6;
    padding: 0.5rem 0.75rem;
    line-height: 1.25;
    margin-left: -1px;
}

.pagination-left-part {
    justify-content: start !important;
}

.pagination-right-part {
    justify-content: flex-end !important;
    padding-right: 8px;
}

.login-email {
    display: inline;
}

@media (max-width: 768px) {
    .login-email {
        display: none;
    }
    .pagination-right-part {
        justify-content: center !important;
        padding-right: 0px;
        display: none !important;
    }
    .pagination-left-part {
        justify-content: center !important;
    }
}
